/* Container */
.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Form */
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Input fields */
  input,
  select {
    /* width: 100%; */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
  }
  
  input:focus,
  select:focus {
    border-color: #007bff;
  }
  
  /* Submit button */
  button {
    padding: 12px 16px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Player details */
  .playerDetails {
    padding: 10px;
    background-color: #eef5ff;
    border: 1px solid #007bff;
    border-radius: 4px;
    color: #333;
    font-size: 14px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .container {
      padding: 15px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    input,
    select,
    button {
      font-size: 14px;
      padding: 8px;
    }
  
    .playerDetails {
      font-size: 12px;
    }
  }
  